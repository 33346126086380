import './_perguntas.scss';
import CardPerguntas from '../../components/cardPerguntas/cardperguntas';
import { useContext, useEffect, useState, Suspense } from 'react';
import { AdminContext } from '../../contexts/temasAdminContext';
import CardResumePerguntas from '../../components/cardResumePerguntas/cardResumePerguntas';
import { AuthContext } from '../../contexts/auth';
import { ManagerProgressContext } from '../../contexts/managerProgres';
import Loading from '../../components/loading/loading';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import configeToken from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Perguntas() {
    const { updateToken, logoutLogin, cyrcleStorage} = useContext(AuthContext)
    const { getProgress } = useContext(ManagerProgressContext)
    const { subTema, subTemaId } = useContext(AdminContext)
    const [dataTema, setDataTema] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [newRender, setNewRender] = useState(false)
    const [permission, setPermission] = useState(false);
    const [scrollBtnPost, setScrollBtnPost] = useState(0)
    const { id } = useParams();
    const navigate = useNavigate()
    const renderButton = dataTema.endQuestion
    const [ finishTema, setFinishTema ] = useState([])

    useEffect(() => {
        document.title = 'Abrafati - Questionário'
        const download = JSON.parse(localStorage.getItem('@user')).permissions;
        download.forEach((permissao) => {
            if (permissao.id === 2) {
                setPermission(true);
            }
        })
    }, [])

    useEffect(() => {
        setRemoveLoading(false)
    }, [id])

    useEffect(() => {
        if(!id) return

        setScrollBtnPost(0)
        updateToken()
            .then(() => getProgress())
            .then(() => configeToken())
            .then(response => {
                if (subTemaId === '' && scrollBtnPost === 0) {
                    window.scroll({ top: 0 })
                }

                const headers = {
                    "Authorization": ``,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                };

                axios({ method: "get", url: `${process.env.REACT_APP_API_URL}/tema/${id}`, headers })
                    .then((response) => {
                        setDataTema(response.data.data)

                        setRemoveLoading(true)

                        let percentualQuestions = response.data.data.subthemes?.map(el => {

                            return el.progress
                        }).filter(el => el !== 100).length

                        setFinishTema(!!percentualQuestions)
                    })
                    .catch(() => {
                    })
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logoutLogin();
                }
            });
    }, [id, newRender])

    useEffect(() => {
        if(cyrcleStorage > 1 && Number(id) <= 4) {
            navigate(`/questionario/${Number(id)+4}`)
        }
        if(cyrcleStorage === 1 && Number(id) > 4) {
            navigate(`/questionario/${Number(id)-4}`)
        }
    },[cyrcleStorage])

    async function postDataQuestion(question_id, option_id) {
        let dateQuestion =  JSON.parse(localStorage.getItem('@tetse'))
        let question_idStorage = dateQuestion && dateQuestion[0]
        let option_idStorage = dateQuestion && dateQuestion[1]

        const headers = {
            "Authorization": `Bearer ${localStorage.getItem('@token')}`,
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        let body

        if(question_id){
            body = {
                "quiz_id": cyrcleStorage,
                "question_id": question_id,
                "option_id":  option_id
            };
        }else{
            body = {
                "quiz_id": cyrcleStorage,
                "question_id": question_idStorage,
                "option_id": option_idStorage
            };
        }

        let responseFetch
        try{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/resposta`, {
                method: "POST",
                headers,
                body: JSON.stringify(body),
            })
            responseFetch = await response.json()

            setScrollBtnPost(window.scrollY)
            setNewRender(!newRender)

            if (response.ok) {
                toast.success('Salvo com sucesso!', {
                    position: 'bottom-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            } else {
                throw responseFetch;
            }

            if(question_idStorage) {
                toast.success('Parabéns, você finalizou todos os questionários', {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }

            if(!response.ok) {
                throw (responseFetch)
            }
        }
        catch (error) {
            toast.warn(`${error}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    }

    return (
        <section className='container-perguntas'>
            <ToastContainer
                position='bottom-right'
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
            <div className="main-perguntas">

                {!removeLoading ? <Loading /> :
                    <>
                        {
                            permission ? (
                                <>
                                    <CardResumePerguntas title={dataTema.title} description={dataTema.description} image={dataTema.image} progress={Math.round(dataTema.progress)} color={`color-${subTema}`} bg={`bg-${subTema}`} />
                                    {
                                        dataTema.subthemes.map(el => <CardPerguntas finishTema={finishTema} lastQuestion={renderButton} postDataQuestion={postDataQuestion} key={el.id} questions={el.questions} title={el.title} id={el.id} color={`color-${subTema}`} bg={`bg-${subTema}`} />)
                                    }
                                </>
                            ) : (
                                navigate('/')
                            )
                        }
                    </>
                }

                { renderButton && removeLoading && finishTema && <button onClick={() => postDataQuestion()} className='btn-send-request' type='button'>Finalizar</button> }
            </div>
        </section>
    )
}

export default Perguntas;
