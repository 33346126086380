import './_menu.scss';
import logoAbrafati from '../../assets/logo-abrafati.png';
import closedIcon from '../../assets/popClosed.png';
import bandeira from '../../assets/bandeira.png';
import coracao from '../../assets/coracao.png';
import busto from '../../assets/busto.png';
import folhas from '../../assets/folhas.png';
import manual from '../../assets/manual.png';
import relatorio from '../../assets/relatorio.png';
import download from '../../assets/download.png';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AdminContext } from '../../contexts/temasAdminContext';
import { AuthContext } from '../../contexts/auth';
import axios from 'axios';

function Menu() {
    const navigate = useNavigate();
    const { logoutLogin } = useContext(AuthContext);
    const [active, setShowBx] = useState(false);
    const { setSubTema, setSubTemaId, subTema } = useContext(AdminContext);
    const { cyrcleStorage } = useContext(AuthContext);
    const [temas, setTemas] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [animateOut, setAnimateOut] = useState(false);
    const [permissionDownload, setPermissionDownload] = useState(false);
    const [permissionManual, setPermissionManual] = useState(false);
    const [permissionTemas, setPermissionTemas] = useState(false);
    const perm = JSON.parse(localStorage.getItem('@user')).permissions;
    const { pathname } = useLocation();

    useEffect(() => {
        const fetchData = () => {
            const cycleChoosedStorage = JSON.parse(localStorage.getItem("@cycleChosed"));
            const token = localStorage.getItem("@token");
    
            const headers = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            };
    
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/pesquisa/${cycleChoosedStorage}`,
                headers,
            })
            .then((response) => {
                setTemas(response.data.data.themes);
    
                if (response.data.data.concluded) {
                    if (pathname === "/") {
                        setPopupMessage("Todos os questionarios foram concluídos!");
                        setShowPopup(true);
                        setAnimateOut(false); // Garante que a animação de subida seja usada ao abrir
                    } else if (pathname.includes('/questionario/')) {
                        setPopupMessage("Questionario finalizado!");
                        setShowPopup(true);
                        setAnimateOut(false);
                    } else {
                        // Ativa a animação de descida antes de fechar o popup
                        setAnimateOut(true);
                        setTimeout(() => setShowPopup(false), 500); // Aguarda a animação antes de ocultar
                    }
                } else {
                    setAnimateOut(true);
                    setTimeout(() => setShowPopup(false), 500);
                }
            })
            .catch((error) => console.error("Erro ao buscar dados:", error));
        };
    
        fetchData();
    }, [cyrcleStorage, pathname]);
    

    useEffect(() => {
        document.title = 'Sistema de Avaliação da Sustentabilidade';
        perm.forEach((permissao) => {
            if (permissao.id === 1) setPermissionDownload(true);
            if (permissao.id === 2) setPermissionManual(true);
            if (permissao.id === 3) setPermissionTemas(true);
        });

        return () => {
            document.title = 'Login';
        };
    }, []);

    useEffect(() => {
        let menus = document.querySelectorAll('.menu-item');
        if (subTema === 0) return;

        menus.forEach(el => {
            if (Number(el.getAttribute('data-order')) === subTema - 1) {
                el.classList.add('bold');
            } else {
                el.classList.remove('bold');
            }
        });
    }, [subTema]);

    function activeMenu(order, index) {
        let menus = document.querySelectorAll('.menu-item');
        menus.forEach(el => {
            if (Number(el.getAttribute('data-order')) === order) {
                el.classList.add('bold');
            } else {
                el.classList.remove('bold');
            }
        });
        if (index <= 4) setSubTema(index);
        setSubTemaId('');
    }

    const activeBx = () => setShowBx(!active);

    const closePopup = () => {
        setAnimateOut(true); // Ativa a animação de descida
        setTimeout(() => {
            setShowPopup(false); // Oculta dps da animação
            setAnimateOut(false); // Reseta o estado de animação
        }, 500); // Ajuste de tempo de duração anima
    };

    return (
        <div className="menu-container">
            {showPopup && (
                <div className={`popup-warn ${animateOut ? 'slide-down' : 'slide-up'}`}>
                    <div className="popup-warn-content">
                        <p>{popupMessage}</p>
                        <img onClick={closePopup} src={closedIcon} alt="Botão fechar" />
                    </div>
                </div>
            )}

            <div onClick={activeBx} className={active ? 'icon iconActive' : 'icon'}>
                <div className="menu-bx"></div>
            </div>

            <aside className='temas__menu' id={active ? 'menu-open' : 'menu-close'}>
                <div className="menu-dkt">
                    {permissionTemas ? (
                        <img onClick={() => navigate('/')} src={logoAbrafati} alt="logomarca Abrafati" />
                    ) : (
                        <>
                            {permissionDownload ? (
                                <img onClick={() => navigate('/downloads')} src={logoAbrafati} alt="logomarca Abrafati" />
                            ) : (
                                <img onClick={() => navigate('/manual')} src={logoAbrafati} alt="logomarca Abrafati" />
                            )}
                        </>
                    )}
                    {permissionTemas && (
                        <div className='PrimeiraSeção'>
                            <div className="box-menu-temas">
                                <img src={bandeira} alt="icone bandeira" />
                                <Link onClick={() => activeMenu(0, 1)}
                                    to={`/questionario/${temas[0]?.id}`}
                                    className={`menu-item ${pathname.includes(temas[0]?.id) && 'bold'}`}
                                    id='menu-1' data-order='0'>
                                        Governança e Liderança
                                </Link>
                            </div>

                            <div className="box-menu-temas">
                                <img src={coracao} alt="ilustração de um coração feito com a junção de duas mãos" />
                                <Link onClick={() => activeMenu(1, 2)}
                                    to={`/questionario/${temas[1]?.id}`}
                                    className='menu-item' id='menu-2' data-order='1'>
                                        Capital Humano
                                </Link>
                            </div>

                            <div className="box-menu-temas">
                                <img src={busto} alt="icone representando uma pessoa, um busto" />
                                <Link onClick={() => activeMenu(2, 3)}
                                    to={`/questionario/${temas[2]?.id}`}
                                    className={`menu-item ${pathname.includes(temas[2]?.id) && 'bold'}`}
                                    id='menu-3' data-order='2'>
                                        Capital Social
                                </Link>
                            </div>

                            <div className="box-menu-temas">
                                <img src={folhas} alt="ilustrção folhas" />
                                <Link onClick={() => activeMenu(3, 4)}
                                    to={`/questionario/${temas[3]?.id}`}
                                    className='menu-item' id='menu-4' data-order='3'>
                                    Meio Ambiente
                                </Link>
                            </div>
                        </div>
                    )}

                    <div className='SegundaSeção'>
                        {permissionManual && (
                            <Link to='/manual'>
                                <div className="box-menu-temas" onClick={() => activeMenu(4)}>
                                    <img src={manual} alt="ilustração folhas" />
                                    <p className='menu-item' id='menu-5' data-order={4}>Manual de Preenchimento</p>
                                </div>
                            </Link>
                        )}

                        {permissionTemas && (
                            <Link to='/relatorio'>
                                <div className="box-menu-temas" onClick={() => activeMenu(5)}>
                                    <img src={relatorio} alt="ilustração folhas" />
                                    <p className='menu-item' id='menu-6' data-order={5}>Resultados</p>
                                </div>
                            </Link>
                        )}

                        {permissionDownload && (
                            <Link to='/downloads'>
                                <div className="box-menu-temas" onClick={() => activeMenu(6)}>
                                    <img src={download} alt="ilustrção folhas" />
                                    <p className='menu-item' id='menu-7' data-order={6}>Downloads</p>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>

                <Link to='/politica-de-privacidade' className='check-privacy'>Política de privacidade</Link>

                <div className='btn-logout' onClick={logoutLogin}>Sair</div>
            </aside>
        </div>
    );
}

export default memo(Menu);
