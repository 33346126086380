import Button from '../button/button';
import Input from '../input/Input';
import logoAbrafati from '../../assets/logo-abrafati.png';
import './_renewPassword.scss';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import Loading from '../loading/loading';

function RenewPassword({revertLayout}){
  const navigate = useNavigate()
  const emailRef = useRef(null)
  const [messageRenew, setMessageRenew] = useState('')
  const [loading, setLoading] = useState(false)


  function renewPassword(){
    setLoading(true)

    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
    };
  
    let body = {
        "email": `${emailRef.current.value}`
    };
  
    fetch(`${process.env.REACT_APP_API_URL}/esqueceu-senha`, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    })
    .then(response => response.json())
    .then( data => setMessageRenew(data.message))
    .finally(() =>{
      setLoading(false)
    })
  }

  function warningMessage(){
    const spanWarning = document.querySelector('.required')
    spanWarning.style.display = "block";
  }

  warningMessage()
  
  
  return(
      <div className='container-renovar-senha'>
      {loading && <Loading />}
          <div className='box-apresentation'>
            <h2 className='title'>Registre sua nova senha</h2>
            <p className='subtitle'>Por favor, digite seu email.</p>
          </div>

          <form action="">
            <Input useRef={emailRef} key='email' text={messageRenew} type='text' id="email" label="E-mail" placeholder="Digite seu e-mail" />
            
            <div className='box-interation' >
              <a className='cadastro' onClick={() => navigate('/cadastro')}>Ou cadastre-se</a>

          <Button onClick={renewPassword} type='button'>{loading ? 'Enviando...' : 'Enviar'}</Button> 

              <div className='box-return-login'>
                <p>Deseja fazer login?</p>
                <span onClick={revertLayout} className='btn-return-login'>Clique aqui</span>
              </div>
            </div>

            <img className='logo-abrafati' src={logoAbrafati} alt='logomarca Abrafati' />
          </form>
    </div> 
  )
}

export default RenewPassword;