import "./_dowloads.scss";
import CardDowload from "../../components/cardDowload/cardDowload";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import { redirect, useNavigate } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import axios from "axios";
import CardManual from "../../components/cardManual/cardManual";
import { useState } from "react";
import Loading from "../../components/loading/loading";

function Dowloads() {
    const [cardsDowloads, setCardsDowloads] = useState([]);
    const { updateToken, logoutLogin } = useContext(AuthContext);
    const navigate = useNavigate();
    const [renderLoading, setRenderLoading] = useState(true);
    const [permission, setPermission] = useState(false);
    const ciclysAkatu = JSON.parse(localStorage.getItem("@ciclysAkatu"));
    const sortedCiclysAkatu = ciclysAkatu.sort((a, b) => b.cycle.localeCompare(a.cycle));

    useEffect(() => {
        const download = JSON.parse(localStorage.getItem("@user")).permissions;
        if (download.length === 0) {
            navigate("/manual");
        } else {
            download.forEach((permissao) => {
                if (permissao.id === 1) {
                    setPermission(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        updateToken();
        document.title = "Abrafati - Downloads";

        const url = `${process.env.REACT_APP_API_URL}/downloads`;

        axios({
            method: "get",
            url: url,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((response) => {
            setCardsDowloads(response.data.data);
            setRenderLoading(false);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                logoutLogin();
            }
        });
    }, [logoutLogin]);

    return (
        <div className="container-dowload">
            {renderLoading ? (
                <Loading />
            ) : (
                <>
                    {permission ? (
                        <main className="container-dowload-main">
                            <div
                                className="box-previous-page"
                                onClick={() => navigate("/")}
                            >
                                <span className="icon-iteration">
                                    <MdChevronRight />
                                </span>
                                <h2 className="title">Downloads</h2>
                            </div>

                            <div className="container-dowload__box-cards">
                                {sortedCiclysAkatu.map((item) => {
                                    return (
                                        <div className="container-card-dowload">
                                            <CardDowload
                                                anoId={item.quiz_id}
                                                title={`Relatório anual - Ciclo ${item.cycle}`}
                                            />
                                        </div>
                                    );
                                })}

                                {cardsDowloads.map((card, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="container-card-dowload"
                                        >
                                            <CardManual
                                                id={card.id}
                                                src={card.path_photo}
                                                title={card.title}
                                                hrefCard={
                                                    card.path ? card.path : null
                                                }
                                                classBtn={"btn-dowload"}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </main>
                    ) : (
                        <>{navigate("/")}</>
                    )}
                </>
            )}
        </div>
    );
}

export default Dowloads;

