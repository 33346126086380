import './_loading.scss';

function Loading({message}){
    return(
        <div className="container-loading">

            {message && <div className='message'>{message}</div>}
            <div className='container'>
                <div className='circle circle-1'></div>
                <div className='circle circle-2'></div>
                <div className='circle circle-3'></div>
            </div>
        </div>
    )
}

export default Loading;