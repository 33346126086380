import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../contexts/auth';
import Input from '../input/Input';
import './_formLogin.scss';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import logoAbrafati from '../../assets/logo-abrafati.png';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../button/button';
import Loading from '../loading/loading'

function FormLogin({ revertLayout }) {
  const [usePassword, setUsePassword] = useState('password');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { getLogin, showAlertDatasLogin, messageLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const emailRef = useRef(null);
  const senhaRef = useRef(null);

  const location = useLocation();
  const successMessage = location.state?.successMessage || '';

  function visiblePassword() {
    const password = document.querySelector('#senha');
    if (password.type === 'password') {
      password.type = 'text';
      setUsePassword('text');
    } else {
      password.type = 'password';
      setUsePassword('password');
    }
  }

  useEffect(() => {
    if (showAlertDatasLogin) {
      let alertRequire = document.querySelectorAll('.required');
      alertRequire[1].style.display = "block";
      setLoading(false);
    }
  }, [showAlertDatasLogin]);

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let email = queryParams.get('email');
    let token = queryParams.get('token');
    
    if (token != null) {
      getLogin(email, 'senha', token);
    }
  }, [location.search, getLogin]);

  function validateFields() {
    if (emailRef.current.value === '' || senhaRef.current.value === '') {
      setErrorMessage('Por favor, preencha todos os campos.');
      return false;
    }
    return true;
  }
  
  async function sendLogin(e) {
    e.preventDefault();
    setErrorMessage('');

    if (!validateFields()) {
      setLoading(false);
      return;
    }
    
    setLoading(true);
    try {
      await getLogin(emailRef.current.value, senhaRef.current.value);
    } catch (error) {
      setErrorMessage('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
      setLoading(false);
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <div className='login__box-left'>
      {loading && <Loading />}
      <div className='box-apresentation'>
        <h2 className='title'>Seja bem vindo</h2>
        <p className='subtitle'>Por favor, coloque suas informações.</p>
      </div>

      <form onSubmit={sendLogin}>
        <Input useRef={emailRef} key='email' type='text' id="email" label="E-mail" placeholder="Digite seu e-mail" />
        <Input useRef={senhaRef} key='senha' text={messageLogin} type='password' id="senha" label="Senha" placeholder="Digite sua senha" />

        {usePassword === 'text' ? <AiOutlineEye onClick={visiblePassword} className='logo-invisible' /> : <AiOutlineEyeInvisible onClick={visiblePassword} className='logo-invisible' />}


        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}

        {errorMessage && (
          <div className='error-message'>
            {errorMessage}
          </div>
        )}

        <div className='box-interation'>
          <span className='cadastro' onClick={() => navigate('/cadastro')}>Ou cadastre-se</span>

          <Button type='submit' disabled={loading}>
            {loading ? 'Entrando...' : 'Entrar'}
          </Button>

          <div className='box-renovar-senha'>
            <p>Esqueceu sua senha?</p>
            <span onClick={revertLayout} className='link-renovar-senha'>Clique aqui</span>
          </div>
        </div>
        <img className='logo-abrafati' src={logoAbrafati} alt='logomarca Abrafati' />
      </form>
    </div>
  );
}

export default FormLogin;
