import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import axios from "axios";

export const AuthContext = createContext()

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null)
  const [showAlertDatasLogin, setShowAlertDatasLogin] = useState(false)
  const [messageLogin, setMessageLogin] = useState('')
  const [isExpired, setIsExpired] = useState(false)
  const [cyrcleStorage, setCyrcleStorage] = useState(false)

  useEffect(() => {
    setCyrcleStorage( JSON.parse(localStorage.getItem('@cycleChosed')) )

    const loadStorageData = async () => {
      const storageUser = JSON.parse(localStorage.getItem('@user'))
      if (storageUser) {
        setUser(storageUser)
      }
    }
    loadStorageData()
  }, [])
  useEffect(() => {
    if (isExpired) logoutLogin()
  }, [isExpired])


  function getLogin(email, senha, token = null) {
    fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body: JSON.stringify({
        "email": email,
        "password": senha,
        "token": token
      })
    })
      .then((response) => response.json())
      .then((response) => {
        if (typeof (response.message) === 'string') {
          setMessageLogin(response.message)
          setShowAlertDatasLogin(!showAlertDatasLogin)
        } else {
            setShowAlertDatasLogin(!showAlertDatasLogin)
            localStorage.setItem("@ciclysAkatu", JSON.stringify(response.user.cycles))
            localStorage.setItem('loginHour', moment())
            localStorage.setItem('@token', response.token.access_token)
            localStorage.setItem('@user', JSON.stringify(response.user))
            localStorage.setItem('@time', JSON.stringify(moment().add(3480, 'second').format('DD/MM/YYYY H:m:s')))
            setUser(response.user)


          if (response.user.cycles.length > 1) {
            const latestCycle = response.user.cycles.reduce((latest, cycle) => {
              return parseInt(cycle.cycle) > parseInt(latest.cycle) ? cycle : latest
            })
            
            setCyrcleStorage(latestCycle.quiz_id)
            localStorage.setItem("@cycleChosed", JSON.stringify(latestCycle.quiz_id))

          } else {
            setCyrcleStorage(response.user.cycles[0].quiz_id)
            localStorage.setItem("@cycleChosed", JSON.stringify(response.user.cycles[0].quiz_id))
          }
        }
      })
  }


  function logoutLogin() {
    setUser(null)
    localStorage.clear()
  }

  async function updateToken() {
    let checkHour = moment(localStorage.getItem('loginHour')).toNow()

    if(Number(checkHour.replace(/[^0-9]/g,'')) < 40 && Number(checkHour.replace(/[^0-9]/g,'')) === 0) return
    if(checkHour.includes('hora') === false && checkHour.includes('horas') === false) return

    const headers = {
      "Authorization": ``,
      "Accept": "application/json",
    }

    axios({ method: "post", url: `${process.env.REACT_APP_API_URL}/refresh`, headers })
      .then((response) => {
        localStorage.setItem('@token', response.data.access_token)
      })
      .catch(() => {
        logoutLogin()
      })

  }

  return (
    <AuthContext.Provider value={{ getLogin, signed: user !== null, cyrcleStorage, setCyrcleStorage, logoutLogin, setIsExpired, setUser, updateToken, showAlertDatasLogin, messageLogin }} >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;
