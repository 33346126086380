import './_temas.scss';
import '../../css/partials/_colors.scss';
import CardHeadTemas from '../../components/cardTemas/cardHeadTemas';
import CardTema from '../../components/cardTema/cardTema';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import Loading from '../../components/loading/loading';
import axios from 'axios';
import configeToken from '../../services/api';
import { useNavigate } from 'react-router-dom';

function Temas() {
    const { updateToken, logoutLogin, cyrcleStorage } = useContext(AuthContext)
    const [getTemas, setGetTemas] = useState([])
    const [removeLoading, setRemoveLoading] = useState(false)
    const [permission, setPermission] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    logoutLogin();
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [logoutLogin]);

    useEffect(() => {
        document.title = 'Abrafati - Temas'
        const permissions = JSON.parse(localStorage.getItem('@user')).permissions;
        const permission = permissions.find(permission => permission.id === 2);

        if(permission !== undefined) {
            setPermission(true);
        }
    }, [])

    useEffect(() => {
        if(!cyrcleStorage) return

        updateToken()
            .then(() => configeToken())
            .then(() => {
                const headers = {
                    "Authorization": `Bearer ${localStorage.getItem('@token')}`,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                };

                axios({ method: "get", url: `${process.env.REACT_APP_API_URL}/pesquisa/${cyrcleStorage}`, headers })
                    .then((response) => {
                        setGetTemas(response.data.data.themes)
                        setRemoveLoading(true)
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            logoutLogin();
                        }
                    });
            })
    }, [cyrcleStorage, logoutLogin])

    return (
        <div className='container-temas'>
            {!removeLoading ? <Loading /> : (
                <>
                    {
                        permission ? (
                            <main className='container-temas-main' >
                                <CardHeadTemas getTemas={getTemas} />
                                <>
                                    {
                                        getTemas.map(el => <CardTema key={el.id} idTema={el.id} subthemes={el.subthemes} progress={Math.round(el.progress)} title={el.title} introduction={el.introduction} image={el.image} temaBg={`bg-${el.id}`} temaColor={`color-${el.id}`} />)
                                    }
                                </>
                            </main>
                        ) : (
                            navigate('/downloads')
                        )
                    }
                </>
            )}
        </div >
    )
}

export default Temas;
