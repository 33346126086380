import './_button.scss'

function Button({children, type, onClick, disabled = false}){
    return(
        <>
            <button onClick={onClick} type={type} disabled={disabled} className='btn'>{children}</button>
        </>
    )
}

export default Button;