import './_cardHeadTemas.scss';
import wppTemas from '../../assets/institucional.jpg';

function CardHeadTemas({getTemas}){
    return(
        <div className='card-temas' >
            <div className='card-temas__introduction'>
                <p className="card-temas__text-introduction">
                    O Sistema de Avaliação da Sustentabilidade para o segmento de tintas e vernizes, 
                    desenvolvido em conjunto com o Instituto Akatu, representa a evolução do compromisso do setor 
                    em termos de seu desenvolvimento de programas, procedimentos e soluções voltados à sustentabilidade.
                </p>
            </div>

            <img className='card-temas__img' src={wppTemas} alt="imagem pessoas sorrindo"/>

            <div className='card-temas__description'>
                <p>
                    Os Indicadores de Sustentabilidade foram estruturados de forma a possibilitar avaliar o grau de 
                    maturidade das empresas do segmento de tintas e vernizes segundo as suas práticas de governança corporativa,
                    sociais e ambientais. Estão organizados em quatro grandes temas com seus respectivos subtemas:
                </p>
            </div>
        </div>
    )
}

export default CardHeadTemas;