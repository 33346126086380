import "./_cadastro3.scss";
import Button from "../../components/button/button";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { DataRegistrationContext } from "../../contexts/contexts";
import { useEffect } from "react";
import Popup from "../../components/popup/popup";
import Privacidade from "../politica-de-privacidade-publico/privacidade_publico";
import configeToken from "../../services/api";
import axios from "axios";
import Loading from "../../components/loading/loading";

function Cadastro3() {
    const navigate = useNavigate();
    const [buttonPopup, setButtonPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tipoEmpresa, setTipoEmpresa] = useState([]);
    const [tipoCapital, setTipoCapitalEmpresa] = useState([]);
    const [faturamentoEmpresa, setFaturamentoEmpresa] = useState([]);
    const {
        registrationClient,
        company_type_id,
        setCompany_type_id,
        type_capital_id,
        setType_capital_id,
        annual_billing_id,
        setAnnual_billing_id,
        number_of_employees,
        setNumber_of_employees,
        showPopEmail,
        setShowPopEmail,
        showPopAdmin,
        setShowPopAdmin,
    } = useContext(DataRegistrationContext);

    useEffect(() => {
        configeToken()
        const headers = {
            Authorization: '',
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        axios({method: "get", url:`${process.env.REACT_APP_API_URL}/tipos-empresa`, headers})
        .then((response) => {
            setTipoEmpresa(response.data.data)
        })    

        axios({method: "get", url:`${process.env.REACT_APP_API_URL}/tipos-capital`, headers})
        .then((response) => {
            setTipoCapitalEmpresa(response.data.data)
        }) 

        axios({method: "get", url:`${process.env.REACT_APP_API_URL}/faturamento-anual`, headers})
        .then((response) => {
            setFaturamentoEmpresa(response.data.data)
        })
        .finally(() => {
            setLoading(false)
        })
    }, []);

    function onChangeTypeCompany(e) {
        setCompany_type_id(e.target.value);
        let alertAll = document.querySelectorAll(".alert-cadastro3");

        if (e.target.value === "") {
            alertAll[0].style.display = "block";
        } else {
            alertAll[0].style.display = "none";
        }
        setLoading(false)
    }

    function onChangeTypeCapital(e) {
        let alertAll = document.querySelectorAll(".alert-cadastro3");
        setType_capital_id(e.target.value);

        if (e.target.value === "") {
            alertAll[1].style.display = "block";
        } else {
            alertAll[1].style.display = "none";
        }
        setLoading(false)
    }

    function onChangeBilling(e) {
        let alertAll = document.querySelectorAll(".alert-cadastro3");
        setAnnual_billing_id(e.target.value);

        if (e.target.value === "") {
            alertAll[2].style.display = "block";
        } else {
            alertAll[2].style.display = "none";
        }
        setLoading(false)
    }

    function onChangeEmployees(e) {
        let alertAll = document.querySelectorAll(".alert-cadastro3");
        setNumber_of_employees(e.target.value);

        if (e.target.value === "") {
            alertAll[3].style.display = "block";
        } else {
            alertAll[3].style.display = "none";
        }
        setLoading(false)
    }

    function registerData(e) {
        e.preventDefault();
        setLoading(true);
        let alertAll = document.querySelectorAll(".alert-cadastro3");
        let alertTermos = document.querySelector(".alert-termos");
        const terms = document.querySelector("#politica-privacidade");

        if (company_type_id === "") {
            alertAll[0].style.display = "block";
        } else {
            alertAll[0].style.display = "none";
        }

        if (type_capital_id === "") {
            alertAll[1].style.display = "block";
        } else {
            alertAll[1].style.display = "none";
        }

        if (annual_billing_id === "") {
            alertAll[2].style.display = "block";
        } else {
            alertAll[2].style.display = "none";
        }

        if (number_of_employees === "") {
            alertAll[3].style.display = "block";
        } else {
            alertAll[3].style.display = "none";
        }

        if (company_type_id === "") return;
        if (type_capital_id === "") return;
        if (annual_billing_id === "") return;
        if (number_of_employees === "") return;

        if (!terms.checked) {
            alertTermos.style.display = "block";
        } else {
            alertTermos.style.display = "none";
        }
        if (!terms.checked){
            setLoading(false)
            return
        };

        registrationClient();
    }

    return (
        <section className="container-cadastro3">
            {loading && <Loading />}
            <div className='container-bg'></div>
            {showPopEmail && (
                <div className="container-pop">
                    <div className="active-pop-email">
                        <p>
                            O e-mail informado já está sendo utilizado. <br />
                            Por favor, registre um diferente.
                        </p>
                        <div
                            onClick={() => setShowPopEmail(!showPopEmail)}
                            className="pop-email-close"
                        >
                            X
                        </div>
                    </div>
                </div>
            )}

            {showPopAdmin && (
                <div className="container-pop">
                    <div className="active-pop-admin">
                        <p>
                            Cadastro feito com sucesso! <br /> 
                            Aguarde que a liberação será enviada para o email cadastrado.
                        </p>
                        <button
                            onClick={() => {
                                navigate("/");
                                setShowPopAdmin(!showPopAdmin);
                            }}
                            className="pop-admin-close"
                        >
                            Ir para tela de login
                        </button>
                    </div>
                </div>
            )}
            <div className="login__box-left">
                <h2 className="title">Cadastro</h2>

                <form onSubmit={registerData}>
                    <p className="title-question3">Tipo de empresa</p>
                    <div className="container-input-registration">
                        <select
                            onChange={(e) => onChangeTypeCompany(e)}
                            value={company_type_id}
                            defaultValue="0"
                            className="box-selecione"
                            name="nacionalidade"
                            id="nacionalidade"
                        >
                            <option value="" selected disabled>
                                Selecione uma opção
                            </option>
                            {tipoEmpresa.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                        </select>
                        <p className="alert-cadastro3">
                            É necessário registrar o tipo da empresa
                        </p>
                    </div>

                    <p className="title-question3">Tipo de capital</p>
                    <div className="container-input-registration">
                        <select
                            onChange={(e) => onChangeTypeCapital(e)}
                            value={type_capital_id}
                            defaultValue="0"
                            placeholder="selecione uma opção"
                            className="box-selecione"
                            name="capital"
                            id="capital"
                        >
                            <option value="" selected disabled>
                                Selecione uma opção
                            </option>
                            {tipoCapital.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                        </select>
                        <p className="alert-cadastro3">
                            É necessário registrar o tipo de capital
                        </p>
                    </div>

                    <p className="title-question3">Faturamento anual</p>
                    <div className="container-input-registration">
                        <select
                            onChange={(e) => onChangeBilling(e)}
                            value={annual_billing_id}
                            defaultValue="0"
                            className="box-selecione"
                            name="faturamento"
                            id="faturamento"
                        >
                            <option value="" selected disabled>
                                Selecione uma opção
                            </option>
                            {faturamentoEmpresa.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.value}
                                </option>
                            ))}
                        </select>
                        <p className="alert-cadastro3">
                            Registre o faturamento anual
                        </p>
                    </div>

                    <p className="title-question3 title-question3">Numero de funcionários</p>
                    <input
                        onChange={(e) => onChangeEmployees(e)}
                        value={number_of_employees}
                        min="0"
                        type="number"
                        placeholder="Digite o número de funcionários"
                    />

                    <p className="alert-cadastro3">
                        Registre o faturamento anual
                    </p>
                    <div className="box-check-direitos">
                        <input
                            type="checkbox"
                            name="direitos"
                            id="politica-privacidade"
                            value=""
                        />
                        <p htmlFor="1">
                            Eu concordo com a{" "}
                            <a onClick={() => setButtonPopup(true)} href='##'>
                                Politica de privacidade
                            </a>{" "}
                            e <a href="##" >Termos de uso</a>.
                        </p>
                        <p className="alert-termos">
                            É necessário concordar com a Política de privacidade
                            e termos de uso
                        </p>
                    </div>

                    <p className="alert-erro">
                        É necessário preencher todos os campos!
                    </p>
                    <Button type="submit">{loading ? 'Carregando...' : 'Avançar'}</Button>
                </form>

                <Link to="/cadastro2" className="btn-back">
                    Voltar
                </Link>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <Privacidade />
            </Popup>
        </section>
    );
}

export default Cadastro3;
